import video from "./assets/video.mp4";
import newvideo from "./assets/Tony.MOV";
import ReactPlayer from 'react-player'
//import video1 from "https://youtu.be/Kdk_BRX6aO8";
//import video2 from "https://youtu.be/Kdk_BRX6aO8";
//import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './App.css';
//import 'bootstrap/dist/css/bootstrap.css';
//
//const {open} = this.state;
//const {setOpen} = useState(false);

function App() {

  return (
    <div className="App">
    <header className="App-header">
      <h1>Video Testing!</h1>

	   <h2>Testing</h2>
      <video controls width="70%" className="videoPlayer" src={newvideo}></video>
      
      <h2>Testing 1</h2>
      <video controls width="70%" className="videoPlayer" src={video}></video>

      <h2> Testing 2</h2>
      <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />

    </header>
  </div>
  
  );
}

export default App;
